import {DetalhesConta} from './cards/DetalhesConta'
import {Senha} from './cards/Senha'
// import {EmailESenha} from './cards/EmailESenha'
// import { DesativacaoDeConta } from './cards/DesativacaoDeConta'
// import { ContasConectadas } from './cards/ContasConectadas'
import { ContaProvider } from './conta.provider'

export function Conta() {
  return (
    <ContaProvider>
      <DetalhesConta />
      <Senha />
      {/* <EmailESenha /> */}
      {/* <ContasConectadas /> */}
      {/* <DesativacaoDeConta /> */}
    </ContaProvider>
  )
}
