import {createContext, useContext} from 'react'
import {Response, WithChildren} from '../../../../../_metronic/helpers'
//import {IDetalhesConta} from './SettingsModel'
import {UserModel, useAuth} from '../../../auth'
import {useQuery} from '@tanstack/react-query'
import axios from 'axios'
import { enqueueSnackbar } from 'notistack'

export type ContaContextProps = {
  detalhesConta?: UserModel
  refetch: VoidFunction
  atualizarConta(userModel: Partial<UserModel>): Promise<Response<UserModel>>
  redefinirSenha(): Promise<void>
}

const API_URL = process.env.REACT_APP_API_BACK_TODOC
const USUARIO_URL = `${API_URL}/usuarios`

const ContaContext = createContext<ContaContextProps>({} as ContaContextProps)

export function ContaProvider({children}: WithChildren) {
  const auth = useAuth()

  const {isFetching, refetch, data} = useQuery<UserModel>(
    ['LoadAccountData'],
    async () => {
      const response = await axios.get<UserModel>(USUARIO_URL + '/' + auth.currentUser?.id!)
      return response.data
    },
  )

  async function atualizarConta(userModel: Partial<UserModel>) {
    userModel.id = auth.currentUser?.id
    userModel.id_perfil = auth.currentUser?.perfil.id
    const post = await axios.post(USUARIO_URL + '/pessoais', userModel)
    auth.setCurrentUser(post.data);
    refetch()
    return post
  }

  async function redefinirSenha() {
    try {
      await axios.post(USUARIO_URL + '/redefinir-senha');
      enqueueSnackbar('Email para redefinição de senha foi enviado com sucesso!', {variant: 'info'})
    } catch (e:any) {
      enqueueSnackbar(e.response.data.message, {variant: 'error'})
    }
  }

  return (
    <ContaContext.Provider
      value={{
        detalhesConta: data,
        refetch,
        atualizarConta,
        redefinirSenha,
      }}
    >
      {isFetching && <>Carregando...</>}
      {!isFetching && children}
    </ContaContext.Provider>
  )
}

export const useContaProvider = () => useContext(ContaContext)
