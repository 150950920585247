/* eslint-disable jsx-a11y/anchor-is-valid */
import { Outlet, Route, Routes } from 'react-router-dom'
import { PageTitle } from '../../_metronic/layout/core'
import { TemplateListWrapper } from '../modules/templates/TemplatesList'
import { useEffect } from 'react'

const Templates = () => {

  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='/'
          element={
            <>
            <div className="d-flex justify-content-between mb-5">
              
                <PageTitle>Modelos</PageTitle>
              
                <button type="button"
                  className="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_info_template"
                >
                  Instruções
                </button>
              
            </div>

              <TemplateListWrapper />
            </>
          }
        />
      </Route>
    </Routes>
  )
}

export default Templates
