import React, {useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {useContaProvider} from '../conta.provider'
import {enqueueSnackbar} from 'notistack'
import MaskedInput from 'react-text-mask'

const detalhesContaSchema = Yup.object().shape({
  nome: Yup.string().required('Nome é obrigatório'),
  sobrenome: Yup.string().required('Sobrenome é obrigatório'),
  telefone: Yup.string(), //.required('Telefone é obrigatório'),
  nome_organizacao: Yup.string().max(100, 'Máximo de 100 caracteres'),
})

const DetalhesConta: React.FC = () => {
  const {detalhesConta, atualizarConta} = useContaProvider()

  const [loading, setLoading] = useState(false)
  const formik = useFormik({
    initialValues: {
      nome: detalhesConta?.nome || '',
      sobrenome: detalhesConta?.sobrenome || '',
      telefone: detalhesConta?.telefone || '',
      nome_organizacao: detalhesConta?.nome_organizacao || '',
    },
    validationSchema: detalhesContaSchema,
    onSubmit: async (values) => {
      setLoading(true)
      try {
        await atualizarConta({
          ...values,
        })
        enqueueSnackbar('Seus dados foram atualizados', {variant: 'info'})
      } catch (e: any) {
        enqueueSnackbar(e.response.data.message, {variant: 'error'})
      } finally {
        setLoading(false)
      }
    },
  })

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Minha Conta</h3>
        </div>
      </div>

      <div id='kt_account_profile_details' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Nome</label>
              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Nome'
                  {...formik.getFieldProps('nome')}
                />
                {formik.touched.nome && formik.errors.nome && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.nome}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Sobrenome</label>
              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  placeholder='Sobrenome'
                  {...formik.getFieldProps('sobrenome')}
                />
                {formik.touched.sobrenome && formik.errors.sobrenome && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.sobrenome}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span>Telefone</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <MaskedInput
                  mask={[
                    '(',
                    /\d/,
                    /\d/,
                    ')',
                    ' ',
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                    '-',
                    /\d/,
                    /\d/,
                    /\d/,
                    /\d/,
                  ]}
                  className='form-control form-control-lg form-control-solid'
                  id='telefone'
                  placeholder='(XX) XXXXX-XXXX'
                  {...formik.getFieldProps('telefone')}
                  type='text'
                />

                {formik.touched.telefone && formik.errors.telefone && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.telefone}</div>
                  </div>
                )}
              </div>
            </div>

            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span>Perfil</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <div className='fw-bold text-gray-600 col-form-label fw-bold fs-6'>
                  {detalhesConta?.perfil.nome}
                </div>
              </div>
            </div>

            {detalhesConta?.perfil.master && (
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                  <span>Nome da Organização</span>
                </label>
                <div className='col-lg-8 fv-row'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    placeholder='Nome Organização'
                    {...formik.getFieldProps('nome_organizacao')}
                  />
                  {formik.touched.nome_organizacao && formik.errors.nome_organizacao && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.nome_organizacao}</div>
                    </div>
                  )}
                </div>
              </div>
            )}
            {detalhesConta?.nome_organizacao && '' !== detalhesConta?.nome_organizacao && (
              <>
                {!detalhesConta?.perfil.master && (
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                      <span>Organização</span>
                    </label>

                    <div className='col-lg-8 fv-row'>
                      <div className='fw-bold text-gray-600 col-form-label fw-bold fs-6'>
                        {detalhesConta?.nome_organizacao}
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}

          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'Salvar'}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Por favor aguarde...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export {DetalhesConta}
