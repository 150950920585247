import {Fragment, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {Documentos} from '../core/_models'
import clsx from 'clsx'
import {useListView} from '../core/ListViewProvider'
import {ListLoading} from '../../../components/ListLoading'
import {gerarDocumentos} from '../core/_requests'
import {enqueueSnackbar} from 'notistack'
import {Template} from '../../templates/core/_models'
import Select from 'react-select'
import {useQueryResponse} from '../../../components/pageable/query-response.provider'
import {getFormikValid} from '../../../../_metronic/helpers'
import MaskedInput from 'react-text-mask'
import {validarCNPJ} from '../../../services/util'

type Props = {
  isLoading: boolean
  documentos: Documentos
  templates?: Template[]
}

export function EditModalForm({documentos, templates, isLoading}: Props) {
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const [error, setError] = useState<string | undefined>()
  const [selectedTemplate, setSelectedTemplate] = useState<Template | undefined>()

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const qtdCnpj = selectedTemplate?.qtd_cnpjs ?? 1
  const hasCamposCustomizados =
    selectedTemplate?.campos_customizados && selectedTemplate?.campos_customizados.length > 0

  const editUserSchema = Yup.object().shape({
    id_template: Yup.string().required('Escolha um template'),
    descricao: Yup.string().min(3, 'Mínimo de 3 caracteres').max(250, 'Máximo de 250 caracteres'),
    cnpjs: Yup.array()
      .of(
        Yup.string()
          .test('valid-cnpj', 'Insira um CNPJ válido', (value) => Boolean(value && validarCNPJ(value)),)
          .max(18, 'Apenas 14 digitos')
      )
      .min(qtdCnpj, `Entre com o(s) ${qtdCnpj} CNPJ(s)`)
      .required('Entre com o(s) CNPJ(s)'),
    ...(hasCamposCustomizados && {
      campos_customizados: Yup.array()
        .of(
          Yup.object()
            .shape({
              chave: Yup.string(),
              valor: Yup.string().required('Campo Obrigatório'),
            })
            .required('Campo Obrigatório')
        )
        .min(selectedTemplate.campos_customizados?.length ?? 0, 'Preencha todos os campos'),
    }),
  })

  const formik = useFormik<Documentos>({
    initialValues: {
      ...documentos,
    },
    validationSchema: editUserSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      setError(undefined)
      try {
        await gerarDocumentos({...values, cnpjs: values.cnpjs.map(cnpj => cnpj.replace(/[^\d]+/g, ''))})
        enqueueSnackbar('Documentos salvo com sucesso', {variant: 'info'})
        cancel(true)
      } catch (ex) {
        setError((ex as Error).message || (ex as string))
        console.error(ex)
      } finally {
        setSubmitting(false)
      }
    },
  })

  return (
    <>
      <form
        id='kt_modal_add_colaborador_form'
        className='form'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y'
          id='kt_modal_add_colaborador_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_colaborador_header'
          data-kt-scroll-wrappers='#kt_modal_add_colaborador_scroll'
          data-kt-scroll-offset='300px'
        >
          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Template do Documentos</label>
            {/* end::Label */}

            {/* begin::Input */}
            <Select
              className={clsx(
                {'is-invalid': formik.touched.id_template && formik.errors.id_template},
                {
                  'is-valid': formik.touched.id_template && !formik.errors.id_template,
                }
              )}
              placeholder='Escolha o template'
              {...formik.getFieldProps('template')}
              name='template'
              isDisabled={formik.isSubmitting || isLoading}
              options={templates?.map((t) => ({value: t.id, label: t.nome}))}
              isClearable
              isSearchable
              onChange={(opt) => {
                formik.setFieldValue('id_template', opt?.value)
                setSelectedTemplate(templates?.find((t) => t.id === opt?.value))
              }}
            />
            {formik.errors.id_template && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.id_template}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Descrição do documentos</label>
            {/* end::Label */}

            {/* begin::Input */}
            <textarea
              placeholder='Descrição'
              {...formik.getFieldProps('descricao')}
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.descricao && formik.errors.descricao},
                {
                  'is-valid': formik.touched.descricao && !formik.errors.descricao,
                }
              )}
              name='descricao'
              autoComplete='off'
              disabled={formik.isSubmitting || isLoading}
            />
            {/* end::Input */}
            {formik.touched.descricao && formik.errors.descricao && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors.descricao}</span>
              </div>
            )}
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-5'>CNPJ's</label>
            {/* end::Label */}
            {/* begin::Roles */}
            {/* begin::Input row */}
            <div className='d-flex flex-column gap-3'>
              {/* begin::Radio */}
              {[...Array(qtdCnpj)].map((_, i) => (
                <Fragment key={i}>
                  <MaskedInput
                    mask={[
                      /\d/,
                      /\d/,
                      '.',
                      /\d/,
                      /\d/,
                      /\d/,
                      '.',
                      /\d/,
                      /\d/,
                      /\d/,
                      '/',
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                      '-',
                      /\d/,
                      /\d/,
                    ]}
                    className={clsx(
                      'form-control form-control-solid mb-4 mb-lg-0',
                      {'is-invalid': formik.touched.cnpjs && formik.errors.cnpjs},
                      {
                        'is-valid': formik.touched.cnpjs && !formik.errors.cnpjs,
                      }
                    )}
                    id={`cnpjs[${i}]`}
                    {...formik.getFieldProps(`cnpjs[${i}]`)}
                    name={`cnpjs[${i}]`}
                    placeholder={`CNPJ ${i + 1}`}
                  />
                  {formik.errors.cnpjs && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>
                          {Array.isArray(formik.errors.cnpjs)
                            ? formik.errors.cnpjs[i]
                            : formik.errors.cnpjs}
                        </span>
                      </div>
                    </div>
                  )}
                </Fragment>
              ))}
            </div>
            {!hasCamposCustomizados && <div className='separator separator-dashed my-5'></div>}
          </div>
          {/* end::Input group */}
          {/* end::Scroll */}

          {hasCamposCustomizados && (
            <div className='mb-7'>
              {/* begin::Label */}
              <label className='required fw-bold fs-6 mb-5'>Campos Personalizados</label>
              {/* end::Label */}
              {/* begin::Roles */}
              {/* begin::Input row */}
              <div className='d-flex flex-column gap-3'>
                {/* begin::Radio */}
                {selectedTemplate.campos_customizados?.map((cc, index) => (
                  <div
                    className='mb-4 mb-lg-0'
                    data-toggle='tooltip'
                    title={cc.descricao}
                    key={cc.chave}
                  >
                    <input
                      type='hidden'
                      name={`campos_customizados.${index}.chave`}
                      value={cc.chave}
                    />
                    <textarea
                      placeholder={cc.chave}
                      {...formik.getFieldProps(`campos_customizados.${index}.valor`)}
                      className={clsx(
                        'form-control form-control-solid mb-3 mb-lg-0',
                        {
                          'is-invalid':
                            getFormikValid(
                              formik,
                              'touched',
                              'campos_customizados',
                              index,
                              'valor'
                            ) &&
                            getFormikValid(formik, 'errors', 'campos_customizados', index, 'valor'),
                        },
                        {
                          'is-valid':
                            getFormikValid(
                              formik,
                              'touched',
                              'campos_customizados',
                              index,
                              'valor'
                            ) &&
                            !getFormikValid(
                              formik,
                              'errors',
                              'campos_customizados',
                              index,
                              'valor'
                            ),
                        }
                      )}
                      name={`campos_customizados.${index}.valor`}
                      autoComplete='off'
                      disabled={formik.isSubmitting || isLoading}
                      onChange={(evt: any) => {
                        formik.setFieldValue(`campos_customizados.${index}.valor`, evt.target.value)
                        formik.setFieldValue(`campos_customizados.${index}.chave`, cc.chave)
                      }}
                    />
                    {formik.errors.campos_customizados && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>
                            {Array.isArray(formik.errors.campos_customizados)
                              ? (formik.errors.campos_customizados[index] as any)?.valor
                              : formik.errors.campos_customizados}
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </div>
              {/* end::Input row */}
              <div className='separator separator-dashed my-5'></div>
              {/* begin::Input row */}
              {/* end::Input row */}
              {/* end::Roles */}
            </div>
          )}
        </div>

        {/* end::Input group */}

        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-colaboradores-modal-action='cancel'
            disabled={formik.isSubmitting || isLoading}
          >
            Cancelar
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-colaboradores-modal-action='submit'
            disabled={isLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Salvar</span>
            {(formik.isSubmitting || isLoading) && (
              <span className='indicator-progress'>
                Por favor aguarde...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          {error && (
            <div className='alert alert-danger d-flex align-items-center p-5 m-5'>
              {error}
            </div>
          )}
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isLoading) && <ListLoading />}
    </>
  )
}
