import { InfoModal } from "../shared/InfoModal";

const TemplateInfoModal = () => {

  return (
    <>
    <InfoModal id="kt_modal_info_template" header="Instruções de Uso" content={

      <div className='container mt-5'>
        {/* Introdução */}
        <div className='mb-5'>
          <p>
            Este guia explica como criar modelos de documentos no sistema, partindo de um
            documento Word existente (.doc ou .docx). Ao final, você terá um modelo
            configurado com campos variáveis que serão preenchidos automaticamente.
          </p>
        </div>

        {/* Passo 1 */}
        <div className='mb-5'>
          <h3>Passo 1: Preparar o Documento Base</h3>
          <ol>
            <li>
              <strong>Abra o arquivo Word (.doc ou .docx):</strong> Certifique-se de que
              você possui o contrato ou documento base que deseja transformar em um modelo.
            </li>
            <li>
              <strong>Analise o conteúdo do documento:</strong> Identifique os campos que
              precisam ser personalizados, como o nome da empresa, CNPJ, endereço, data,
              etc.
            </li>
          </ol>
        </div>

        {/* Passo 2 */}
        <div className='mb-5'>
          <h3>Passo 2: Substituir os Campos Variáveis</h3>
          <ol>
            <li>
              <strong>Use as palavras-chave padrão:</strong> Substitua os campos variáveis
              no documento pelas palavras-chave fornecidas na tabela padrão do sistema.
              <ul>
                <li>
                  Exemplo: Substitua "Nome da Empresa" por <code>{'{NOME_EMPRESA}'}</code>.
                </li>
                <li>
                  Substitua "CNPJ da Empresa" por <code>{'{CNPJ}'}</code>.
                </li>
              </ul>
            </li>
            <li>
              <strong>Para múltiplas ocorrências:</strong> Use números para diferenciar
              palavras-chave.
              <ul>
                <li>
                  Exemplo: <code>{'{NOME_EMPRESA_1}'}</code> e{' '}
                  <code>{'{NOME_EMPRESA_2}'}</code>.
                </li>
              </ul>
            </li>
            <li>
              <strong>Criando suas próprias palavras-chave:</strong> Crie novas
              palavras-chave se necessário, seguindo estas regras:
              <ul>
                <li>Não use espaços ou caracteres especiais.</li>
                <li>
                  Exemplo: <code>{'{PORCENTAGEM}'}</code>, <code>{'{CLAUSULA_A}'}</code>.
                </li>
              </ul>
            </li>
          </ol>
        </div>

        {/* Passo 3 */}
        <div className='mb-5'>
          <h3>Passo 3: Validar o Uso das Palavras-Chave</h3>
          <ol>
            <li>
              <strong>Verifique a tabela padrão:</strong> Certifique-se de que as
              palavras-chave criadas não entram em conflito com as da tabela padrão do
              sistema.
            </li>
            <li>
              <strong>Revise o documento:</strong> Garanta que todas as palavras-chave foram
              inseridas corretamente e correspondem aos dados que o sistema deverá
              preencher.
            </li>
          </ol>
        </div>

        {/* Passo 4 */}
        <div className='mb-5'>
          <h3>Passo 4: Salvar o Documento</h3>
          <ol>
            <li>
              <strong>Salve o arquivo atualizado:</strong> Após substituir todos os campos
              variáveis, salve o documento em formato Word (.doc ou .docx).
            </li>
            <li>
              <strong>Nomeie o arquivo:</strong> Dê um nome claro, como{' '}
              <code>Modelo_Contrato_Empresa.docx</code>.
            </li>
          </ol>
        </div>

        {/* Passo 5 */}
        <div className='mb-5'>
          <h3>Passo 5: Carregar no Sistema</h3>
          <ol>
            <li>
              <strong>Acesse o módulo de criação de modelos:</strong> Entre no sistema e
              faça o upload do arquivo Word.
            </li>
            <li>
              <strong>Teste o modelo:</strong> Preencha os campos com dados fictícios para
              verificar se tudo está correto.
            </li>
          </ol>
        </div>

        <br />
        {/* Tabela de Palavras-Chave */}
        <div className='mb-5'>
          <h3>Tabela de Palavras-Chave Padrão</h3>
          <div className='table-responsive'>
            <table className='table table-striped gy-7 gs-7'>
              <thead>
                <tr className='fw-bold fs-6 text-gray-900 border-bottom-2 border-gray-600'>
                  <th className='min-w-200px'>Palavra-Chave</th>
                  <th className='min-w-200px'>Descrição</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <code>{'{NOME_EMPRESA}'}</code>
                  </td>
                  <td>Razão social</td>
                </tr>
                <tr>
                  <td>
                    <code>{'{CNPJ}'}</code>
                  </td>
                  <td>CNPJ</td>
                </tr>
                <tr>
                  <td>
                    <code>{'{LOGRADOURO}'}</code>
                  </td>
                  <td>Logradouro</td>
                </tr>
                <tr>
                  <td>
                    <code>{'{NUMERO}'}</code>
                  </td>
                  <td>Número</td>
                </tr>
                <tr>
                  <td>
                    <code>{'{COMPLEMENTO}'}</code>
                  </td>
                  <td>Complemento</td>
                </tr>
                <tr>
                  <td>
                    <code>{'{CEP}'}</code>
                  </td>
                  <td>CEP</td>
                </tr>
                <tr>
                  <td>
                    <code>{'{BAIRRO}'}</code>
                  </td>
                  <td>Bairro</td>
                </tr>
                <tr>
                  <td>
                    <code>{'{CIDADE}'}</code>
                  </td>
                  <td>Cidade</td>
                </tr>
                <tr>
                  <td>
                    <code>{'{SIGLA_ESTADO}'}</code>
                  </td>
                  <td>Sigla Estado</td>
                </tr>
                <tr>
                  <td>
                    <code>{'{NOME_ESTADO}'}</code>
                  </td>
                  <td>Nome Estado</td>
                </tr>
                <tr>
                  <td>
                    <code>{'{DIA_ATUAL}'}</code>
                  </td>
                  <td>Dia atual</td>
                </tr>
                <tr>
                  <td>
                    <code>{'{MES_ATUAL}'}</code>
                  </td>
                  <td>Mês atual</td>
                </tr>
                <tr>
                  <td>
                    <code>{'{ANO_ATUAL}'}</code>
                  </td>
                  <td>Ano atual</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {/* Dicas */}
        <div className='mb-5'>
          <h3>Dicas e Recomendações</h3>
          <ul>
            <li>
              Sempre use as palavras-chave entre chaves <code>{'{}'}</code> para que o
              sistema as reconheça.
            </li>
          </ul>
        </div>
      </div>

    }  />
      
    </>
  )
}

export {TemplateInfoModal}
