import { useContaProvider } from "../conta.provider"

const Senha: React.FC = () => {

  const {redefinirSenha} = useContaProvider()

  const handleClick = async (e: React.MouseEvent) => {
    e.preventDefault();
    await redefinirSenha();
  };

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Senha</h3>
        </div>
      </div>

      <div id='kt_account_signin_method' className='collapse show'>
        <form
              id='kt_signin_change_password'
              className='form'
              noValidate>
          <div className='card-body border-top p-9'>
            <div className='d-flex flex-wrap align-items-center mb-10'>
              <div id='kt_signin_password'>
                <div className='fs-6 fw-bolder mb-1'>Senha</div>
                <div className='fw-bold text-gray-600'>************</div>
              </div>
            </div>
            </div>
            <div className='card-footer d-flex justify-content-end py-6 px-9'>
                 <button id='kt_update_password' type='submit' className='btn btn-primary'
                  onClick={handleClick}
                  >
                  Redefinir
                </button>
            </div>
          </form>
      </div>
    </div>
  )
}

export {Senha}
