import {FC, useMemo, useState} from 'react'
import * as Yup from 'yup'
import {Field, FieldArray, FormikProvider, useFormik} from 'formik'
import {CamposCustomizadosType, initialTemplate, Template} from '../core/_models'
import clsx from 'clsx'
import {useListView} from '../core/ListViewProvider'
import {uploadTemplate} from '../core/_requests'
import {Perfil} from '../../perfil/core/_models'
import {enqueueSnackbar} from 'notistack'
import {UploadComponent} from '../components/upload-component'
import {ListLoading} from '../../../components/ListLoading'
import {useQueryResponse} from '../../../components/pageable/query-response.provider'
import { getFormikValid } from '../../../../_metronic/helpers'

type Props = {
  isTemplateLoading: boolean
  template: Partial<Template>
  perfis?: Perfil[]
}

const editUserSchema = Yup.object().shape({
  template: Yup.object().shape({
    qtd_cnpjs: Yup.number().required('Quantidade de CNPJs é obrigatório'),
    nome: Yup.string()
      .min(3, 'Mínimo de 3 caracteres')
      .max(50, 'Máximo de 50 caracteres')
      .required('Nome é obrigatório'),
    id_perfis: Yup.array().of(Yup.number()),
    campos_customizados: Yup.array().of(
      Yup.object().shape({
        chave: Yup.string()
          .required('Campo obrigatório')
          .matches(/(?!.\s).$/, 'Não pode ter espaços'),
        descricao: Yup.string(),
      })
    ),
  }),
  file: Yup.array().of(Yup.mixed().required('Arquivo é obrigatório')),
});

export type TemplateObject = {
  id_template?: number
  nome: string
  qtd_cnpjs: number
  id_perfis: number[]
  campos_customizados?: CamposCustomizadosType[]
}

export type TemplateFormType = {
  template: TemplateObject
  file: File[]
}

const TemplateEditModalForm: FC<Props> = ({template, isTemplateLoading, perfis}) => {
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const [error, setError] = useState<string | undefined>()

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const formik = useFormik<TemplateFormType>({
    initialValues: {
      template: {
        ...template,
        nome: template.nome ?? initialTemplate.nome,
        qtd_cnpjs: template.qtd_cnpjs || initialTemplate.qtd_cnpjs,
        id_perfis: template.id_perfis || initialTemplate.id_perfis,
        campos_customizados: template.campos_customizados || initialTemplate.campos_customizados,
      },
      file: [],
    },
    validationSchema: editUserSchema,
    onSubmit,
  })

  function validaCampos(values: Partial<TemplateFormType>) {
    let hasError = false
    if (values.template?.campos_customizados) {
      const cc = new Set()
      for (let i = 0; i < values.template?.campos_customizados.length; i++) {
        const v = values.template?.campos_customizados[i]
        if (cc.has(v.chave)) {
          formik.setFieldError(`template.campos_customizados[${i}].chave`, 'Chave identificadora repetida')
          hasError = true
        } else {
          cc.add(v.chave)
        }
      }
    }
    return hasError
  }

  async function onSubmit(values: Partial<TemplateFormType>, {setSubmitting}: any) {
    setSubmitting(true)
    setError(undefined)
    if (validaCampos(values)) {
      return
    }
    try {
      const data = values;
      const masterProfiles = perfis?.filter((p) => p.master)?.map((p) => p.id!) ?? [];
      const selectedProfiles = data.template?.id_perfis ?? [];
      const perfisSelecionados: number[] = [
        ...masterProfiles,
        ...selectedProfiles
      ].filter((p) => p != null) // Remove any null/undefined values
       .filter((value, index, self) => self.indexOf(value) === index); // Remove duplicates

      // Update id_perfis with perfisSelecionados
      if (data.template) {
        data.template.id_perfis = perfisSelecionados;
      }

      await uploadTemplate({
        ...data,
        
      })

      enqueueSnackbar('Modelo salvo com sucesso', {variant: 'info'})
      cancel(true)
    } catch (ex) {
      setError((ex as any).response.data.message || (ex as string))
      console.error(ex)
    } finally {
      setSubmitting(false)
    }
  }

  const perfisFiltrados = useMemo(() => perfis?.filter((p) => !p.master), [perfis])

  return (
    <>
      <form
        id='kt_modal_add_template_form'
        className='form'
        onSubmit={formik.handleSubmit}
        noValidate
      >
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y'
          id='kt_modal_add_template_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_template_header'
          data-kt-scroll-wrappers='#kt_modal_add_template_scroll'
          data-kt-scroll-offset='300px'
        >
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Arquivo</label>
            <UploadComponent
              onDrop={(acceptedFiles: File[]) => {
                formik.setFieldValue('file', acceptedFiles)
              }}
            />
          </div>
          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Nome</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Nome'
              {...formik.getFieldProps('template.nome')}
              type='text'
              name='template.nome'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.template?.nome && formik.errors.template?.nome},
                {
                  'is-valid': formik.touched.template?.nome && !formik.errors.template?.nome,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isTemplateLoading}
            />
            {formik.touched.template?.nome && formik.errors.template?.nome && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.template?.nome}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Qtd. CNPJs</label>
            {/* end::Label */}

            {/* begin::Input */}
            <input
              placeholder='Quantidade  de CNPJs'
              {...formik.getFieldProps('template.qtd_cnpjs')}
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.template?.qtd_cnpjs && formik.errors.template?.qtd_cnpjs},
                {
                  'is-valid': formik.touched.template?.qtd_cnpjs && !formik.errors.template?.qtd_cnpjs,
                }
              )}
              type='number'
              name='template.qtd_cnpjs'
              autoComplete='off'
              disabled={formik.isSubmitting || isTemplateLoading}
            />
            {/* end::Input */}
            {formik.touched.template?.qtd_cnpjs && formik.errors.template?.qtd_cnpjs && (
              <div className='fv-plugins-message-container'>
                <span role='alert'>{formik.errors.template?.qtd_cnpjs}</span>
              </div>
            )}
          </div>
          {/* end::Input group */}

          {perfisFiltrados?.map((p) => (
          <div key={p.id} className='form-check form-check-custom form-check-solid mt-3'>
            <input
              className='form-check-input'
              type='checkbox'
              {...formik.getFieldProps('template.id_perfis')}
              value={p.id}
              checked={p.id !== undefined && formik.values.template?.id_perfis?.includes(p.id)}
              onChange={(e) => {
                const value = Number(e.target.value);
                const currentValues = formik.values.template?.id_perfis || [];
                
                if (e.target.checked) {
                  formik.setFieldValue('template.id_perfis', [...currentValues, value]);
                } else {
                  formik.setFieldValue(
                    'template.id_perfis',
                    currentValues.filter((id) => id !== value)
                  );
                }
              }}
            />
            <label className='form-check-label'>{p.nome}</label>
          </div>
          ))
          }

          <div className='mb-7'>
            {/* begin::Label */}

            {/* end::Label */}
            {/* begin::Roles */}
            {/* begin::Input row */}
            <div className='d-flex flex-column'>
              <FormikProvider value={formik}>
                <FieldArray name='template.campos_customizados'>
                  {({insert, remove, push}) => (
                    <div>
                      <label className='fw-bold fs-6 mb-2 d-flex flex-row align-items-center justify-content-between'>
                        Adicionar campos personalizados
                        <button
                          type='button'
                          className='btn btn-icon btn-primary float-end mt-3'
                          title='Adicionar campo'
                          onClick={() => push({chave: '', descricao: ''})}
                        >
                          <i className='fas fa-plus me-3 mt-3 ms-3 mb-3'></i>
                        </button>
                      </label>
                      {formik.values.template.campos_customizados &&
                        formik.values.template.campos_customizados.length > 0 &&
                        formik.values.template.campos_customizados.map((campo, index) => (
                          <div
                            className='form-check form-check-custom form-check-solid mt-3 d-flex flex-row align-items-start'
                            key={index}
                          >
                            <div className='d-flex flex-column col-5'>
                              <div className='d-flex flex-row align-items-center mb-4 me-2 mb-lg-1'>
                                <span
                                  className='text-muted'
                                  style={{
                                    fontSize: '1.5rem',
                                  }}
                                >
                                  &#123;
                                </span>
                                <Field
                                  className={clsx(
                                    'form-control form-control-solid me-1 ms-1',
                                    {
                                      'is-invalid':
                                        getFormikValid(
                                          formik,
                                          'touched',
                                          'template.campos_customizados',
                                          index,
                                          'chave'
                                        ) &&
                                        getFormikValid(
                                          formik,
                                          'errors',
                                          'template.campos_customizados',
                                          index,
                                          'chave'
                                        ),
                                    },
                                    {
                                      'is-valid':
                                        getFormikValid(
                                          formik,
                                          'touched',
                                          'template.campos_customizados',
                                          index,
                                          'chave'
                                        ) &&
                                        !getFormikValid(
                                          formik,
                                          'errors',
                                          'template.campos_customizados',
                                          index,
                                          'chave'
                                        ),
                                    }
                                  )}
                                  type='text'
                                  {...formik.getFieldProps(`template.campos_customizados.${index}.chave`)}
                                  name={`template.campos_customizados.${index}.chave`}
                                  placeholder={`Nome do Campo ${index + 1}`}
                                  onChange={(evt: any) => {
                                    formik.setFieldValue(
                                      `template.campos_customizados.${index}.chave`,
                                      evt.target.value.replace(/\s/, '').toUpperCase()
                                    )
                                  }}
                                />
                                <span
                                  className='text-muted'
                                  style={{
                                    fontSize: '1.5rem',
                                  }}
                                >
                                  &#125;
                                </span>
                              </div>
                              {formik.errors.template?.campos_customizados && (
                                <div className='fv-plugins-message-container'>
                                  <div className='fv-help-block'>
                                    <span role='alert'>
                                      {Array.isArray(formik.errors.template?.campos_customizados)
                                        ? formik.errors.template?.campos_customizados[index]?.chave
                                        : formik.errors.template?.campos_customizados}
                                    </span>
                                  </div>
                                </div>
                              )}
                            </div>

                            <Field
                              className={clsx(
                                'form-control form-control-solid mb-4 me-4 mb-lg-0',
                                {
                                  'is-invalid':
                                    getFormikValid(
                                      formik,
                                      'touched',
                                      'template.campos_customizados',
                                      index,
                                      'descricao'
                                    ) &&
                                    getFormikValid(
                                      formik,
                                      'errors',
                                      'template.campos_customizados',
                                      index,
                                      'descricao'
                                    ),
                                },
                                {
                                  'is-valid':
                                    getFormikValid(
                                      formik,
                                      'touched',
                                      'template.campos_customizados',
                                      index,
                                      'descricao'
                                    ) &&
                                    !getFormikValid(
                                      formik,
                                      'errors',
                                      'template.campos_customizados',
                                      index,
                                      'descricao'
                                    ),
                                }
                              )}
                              type='text'
                              {...formik.getFieldProps(`template.campos_customizados.${index}.descricao`)}
                              name={`template.campos_customizados.${index}.descricao`}
                              placeholder={`Descrição do Campo ${index + 1}`}
                            />
                            <button
                              type='button'
                              className='btn btn-icon btn-danger'
                              onClick={() => remove(index)}
                              title='Excluir Campo'
                            >
                              <i className='fas fa-trash me-3 mt-3 ms-3 mb-3'></i>
                            </button>
                          </div>
                        ))}
                    </div>
                  )}
                </FieldArray>
              </FormikProvider>
            </div>
            {/* end::Input row */}
            <div className='separator separator-dashed my-5'></div>
            {/* begin::Input row */}
            {/* end::Input row */}
            {/* end::Roles */}
          </div>
        </div>

        {/* begin::Actions */}
        <div className='text-center'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-templatees-modal-action='cancel'
            disabled={formik.isSubmitting || isTemplateLoading}
          >
            Cancelar
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-templatees-modal-action='submit'
            disabled={
              isTemplateLoading || formik.isSubmitting || !formik.isValid || !formik.touched
            }
          >
            <span className='indicator-label'>Salvar</span>
            {(formik.isSubmitting || isTemplateLoading) && (
              <span className='indicator-progress'>
                Por favor aguarde...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          {error && (
            <div className='alert alert-danger d-flex align-items-center p-5 m-5'>
              {error}
            </div>
          )}
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isTemplateLoading) && <ListLoading />}
    </>
  )
}

export {TemplateEditModalForm}
