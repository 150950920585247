import { KTSVG } from "../../../_metronic/helpers/components/KTSVG";

interface InfoModalHeaderProps {
  header: string;
}

const InfoModalHeader = ({ header }: InfoModalHeaderProps) => {
  return (
    <div className='modal-header'>
      {/* begin::Modal title */}
      <h2 className='fw-bolder'>{header}</h2>
      {/* end::Modal title */}

      {/* begin::Close */}
      <div
        className='btn btn-icon btn-sm btn-active-icon-primary'
        data-bs-dismiss="modal"
        style={{cursor: 'pointer'}}
      >
      <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
      </div>
      {/* end::Close */}
    </div>
  )
}

export {InfoModalHeader}
