import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {TemplatesListHeader} from './components/header/TemplatesListHeader'
import {TemplatesTable} from './table/TemplatesTable'
import {TemplateEditModal} from './template-edit-modal/TemplateEditModal'
import {KTCard} from '../../../_metronic/helpers'
import { TemplateInfoModal } from './TemplateInfoModal'

const TemplatesList = () => {
  const {itemIdForUpdate} = useListView()
  return (
    <>
      <KTCard>
        <TemplatesListHeader />
        <TemplateInfoModal />
        <TemplatesTable />
      </KTCard>
      {itemIdForUpdate !== undefined && <TemplateEditModal />}
    </>
  )
}

const TemplateListWrapper = () => (
  <QueryResponseProvider>
    <ListViewProvider>
      <TemplatesList />
    </ListViewProvider>
  </QueryResponseProvider>
)

export {TemplateListWrapper}
